<script setup>
import { computed, ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import {
  DATE_FORMAT_KEYS,
  DELAY,
  getValue,
  toNumberValue,
} from '@emobg/web-utils';
import { useStoreModule } from '@/composable/Store/useStoreModule';
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import {
  GETTERS as CsOperatorGetters,
  nameSpace as CsOperatorNamespace,
} from '@/vue/stores/CSOperator/CSOperatorStore';
import {
  GETTERS as UserDataGetters,
  nameSpace as UserDataNameSpace,
} from '@/vue/stores/UserData/UserDataStore';

const {
  getCurrentCSOperator,
  getVisitedCsOperator,
} = useStoreModule(CsOperatorNamespace, {
  getters: [
    CsOperatorGetters.getCurrentCSOperator,
    CsOperatorGetters.getVisitedCsOperator,
  ],
});
const { userLocale } = useStoreModule(UserDataNameSpace, {
  getters: [UserDataGetters.userLocale],
});

const emits = defineEmits([
  'update:dates',
]);
const props = defineProps({
  bookingType: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  start: {
    type: Object,
    default: () => moment().second(0),
  },
  end: {
    type: Object,
    default: () => moment().second(0),
  },
  overlapOperatorConfig: {
    type: Object,
    default: () => ({}),
  },
  isVerticalLayout: {
    type: Boolean,
    default: false,
  },
});

const startDate = ref(props.start);
const endDate = ref(props.end);
const csOperatorConfiguration = computed(() => ({
  ...getValue(getCurrentCSOperator.value, 'configuration', {}),
  ...getValue(getVisitedCsOperator.value, 'configuration', {}),
  ...getValue(props.overlapOperatorConfig, 'configuration', {}),
}));
const bookingRules = computed(() => {
  const bookingType = props.bookingType || BOOKING_TYPES.carsharing;
  const configByBookingType = {
    [BOOKING_TYPES.carsharing]: {
      maximumBookingDuration: toNumberValue(getValue(csOperatorConfiguration.value, 'cs_maximum_booking_duration', 0)),
      minimumBookingDuration: toNumberValue(getValue(csOperatorConfiguration.value, 'cs_minimum_booking_duration', 0)),
      maximumFutureBookingTime: toNumberValue(getValue(csOperatorConfiguration.value, 'cs_maximum_future_booking_time', 0)),
    },
    [BOOKING_TYPES.longDistance]: {
      maximumBookingDuration: toNumberValue(getValue(csOperatorConfiguration.value, 'ld_maximum_booking_duration', 0)),
      minimumBookingDuration: toNumberValue(getValue(csOperatorConfiguration.value, 'ld_minimum_booking_duration', 0)),
      maximumFutureBookingTime: toNumberValue(getValue(csOperatorConfiguration.value, 'ld_maximum_future_booking_time', 0)),
    },
    [BOOKING_TYPES.intervention]: {
      maximumBookingDuration: toNumberValue(getValue(csOperatorConfiguration.value, 'in_maximum_booking_duration', 0)),
      minimumBookingDuration: toNumberValue(getValue(csOperatorConfiguration.value, 'in_minimum_booking_duration', 0)),
      maximumFutureBookingTime: toNumberValue(getValue(csOperatorConfiguration.value, 'in_maximum_future_booking_time', 0)),
    },
  };

  return {
    ...(configByBookingType[bookingType] || {}),
    minutesToBookBeforeCurrentTime: getValue(csOperatorConfiguration, 'minutes_to_book_before_current_time', 0),
    intervalTimeUnit: getValue(csOperatorConfiguration, 'interval_time_unit', 15),
  };
});
const bookingMoments = computed(() => {
  const {
    intervalTimeUnit,
    maximumFutureBookingTime,
    minimumBookingDuration,
  } = bookingRules.value;
  const now = moment().second(0).millisecond(0);
  const offset = intervalTimeUnit - (now.minutes() % intervalTimeUnit);
  const startMaximumMinutes = maximumFutureBookingTime - minimumBookingDuration;
  const endMinDate = moment(startDate.value).add(minimumBookingDuration, 'm').second(0).millisecond(0);
  const endMaxDate = moment(now).add(maximumFutureBookingTime, 'm');

  return {
    startMinDate: moment(now).add(offset, 'm'),
    startMaxDate: moment(now).add(startMaximumMinutes, 'm'),
    endMinDate,
    endMaxDate: endMaxDate.isBefore(endMinDate) ? endMinDate : endMaxDate,
  };
});

watch(() => props.bookingType, () => {
  const { endMinDate } = bookingMoments.value;

  if (endDate.value.isBefore(endMinDate)) {
    endDate.value = moment(endMinDate);
  }
});
watch(csOperatorConfiguration, (newConfig, oldConfig) => {
  if (isEqual(newConfig, oldConfig)) {
    return;
  }

  const {
    startMinDate,
    endMinDate,
  } = bookingMoments.value;

  startDate.value = moment(startMinDate);
  endDate.value = moment(endMinDate);
});

const debouncedEmitDates = debounce(() => {
  emits('update:dates', { start: startDate.value, end: endDate.value });
}, DELAY.short);

watch([startDate, endDate], () => {
  debouncedEmitDates();
});

const setStartDate = (newStartDate) => {
  if (startDate.value.isSame(newStartDate)) {
    return;
  }

  startDate.value = newStartDate;

  const { minimumBookingDuration } = bookingRules.value;
  const startMinimumDurationDate = moment(startDate.value).add(minimumBookingDuration, 'm');

  if (startMinimumDurationDate.isSameOrAfter(endDate.value)) {
    endDate.value = startMinimumDurationDate;
  }
};

const setEndDate = (newEndDate) => {
  const { endMinDate } = bookingMoments.value;
  endDate.value = newEndDate.isBefore(endMinDate) ? endMinDate : newEndDate;
};
</script>

<template>
  <div
    :class="[
      'BookingPodComponent d-flex',
      { 'flex-column': isVerticalLayout },
    ]"
    data-test-id="booking_pod"
  >
    <div :class="`${ isVerticalLayout ? 'mt-2' : 'flex-fill'}`">
      <ui-datetimepicker
        :date.prop="startDate"
        :disabled="disabled"
        :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
        :range.prop="{
          start: bookingMoments.startMinDate,
          end: bookingMoments.startMaxDate,
        }"
        :interval="bookingRules.intervalTimeUnit"
        :label="$t('new_booking.picker.start')"
        :locale="userLocale"
        external-validation
        immediate
        readonly
        class="w-100"
        data-test-id="start_date"
        @datechanged="({ detail }) => setStartDate(detail)"
      />
    </div>
    <div :class="`${ isVerticalLayout ? 'mt-2' : 'flex-fill ml-4'}`">
      <ui-datetimepicker
        :date.prop="endDate"
        :disabled="disabled"
        :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
        :range.prop="{
          start: bookingMoments.endMinDate,
          end: bookingMoments.endMaxDate,
        }"
        :interval="bookingRules.intervalTimeUnit"
        :label="$t('new_booking.picker.end')"
        :locale="userLocale"
        name="END"
        external-validation
        immediate
        readonly
        class="w-100"
        data-test-id="end_date"
        @datechanged="({ detail }) => setEndDate(detail)"
      />
    </div>
  </div>
</template>
