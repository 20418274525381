var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'BookingPodComponent d-flex',
    { 'flex-column': _vm.isVerticalLayout },
  ],attrs:{"data-test-id":"booking_pod"}},[_c('div',{class:`${ _vm.isVerticalLayout ? 'mt-2' : 'flex-fill'}`},[_c('ui-datetimepicker',{staticClass:"w-100",attrs:{"disabled":_vm.disabled,"date-format-key":_setup.DATE_FORMAT_KEYS.defaultExtended,"interval":_setup.bookingRules.intervalTimeUnit,"label":_vm.$t('new_booking.picker.start'),"locale":_setup.userLocale,"external-validation":"","immediate":"","readonly":"","data-test-id":"start_date"},domProps:{"date":_setup.startDate,"range":{
        start: _setup.bookingMoments.startMinDate,
        end: _setup.bookingMoments.startMaxDate,
      }},on:{"datechanged":({ detail }) => _setup.setStartDate(detail)}})],1),_c('div',{class:`${ _vm.isVerticalLayout ? 'mt-2' : 'flex-fill ml-4'}`},[_c('ui-datetimepicker',{staticClass:"w-100",attrs:{"disabled":_vm.disabled,"date-format-key":_setup.DATE_FORMAT_KEYS.defaultExtended,"interval":_setup.bookingRules.intervalTimeUnit,"label":_vm.$t('new_booking.picker.end'),"locale":_setup.userLocale,"name":"END","external-validation":"","immediate":"","readonly":"","data-test-id":"end_date"},domProps:{"date":_setup.endDate,"range":{
        start: _setup.bookingMoments.endMinDate,
        end: _setup.bookingMoments.endMaxDate,
      }},on:{"datechanged":({ detail }) => _setup.setEndDate(detail)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }