<script setup>
import { ref } from 'vue';
import get from 'lodash/get';
import { useStoreModule } from '@/composable/Store/useStoreModule';
import * as BookingStore from '@/vue/stores/Booking/BookingStore';
import DistanceFilterModal from './DistanceFilterModal';

const isModalOpen = ref(false);
const { estimatedTripMileage } = useStoreModule(BookingStore.nameSpace, {
  state: {
    estimatedTripMileage: state => get(state, 'estimatedTripMileage'),
  },
});
</script>
<template>
  <div class="DistanceFilterBar d-flex align-items-center py-3 px-5 emobg-border-top-1 emobg-border-color-ink-lightest w-100">
    <span class="mr-6">{{ $t('Bookings.DistanceFilter.modal.label') }}:</span>
    <ui-button
      :color="GRAYSCALE.inkLighter"
      :face="FACES.outline"
      :size="SIZES.small"
      @clickbutton="isModalOpen = true"
    >
      <span class="emobg-color-ink"> {{ estimatedTripMileage || 0 }} km </span>
    </ui-button>
    <span class="mx-2"> {{ $t('Bookings.DistanceFilter.modal.text') }} </span>

    <DistanceFilterModal
      v-if="isModalOpen"
      :is-visible="isModalOpen"
      @close-modal="isModalOpen = false"
    />
  </div>
</template>
